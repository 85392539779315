
  // Restaurants

    .restaurants {
      margin: -1rem;

      @media (max-width: $x-small) {
        margin: -1rem 0;
      }

      @media (max-width: $tiny) {
        margin: -.75rem 0;
      }

      .restaurant {
        width: 33.33%;
        padding: 1rem;

        @media (max-width: $large) {
          width: 50%;
        }

        @media (max-width: $x-small) {
          width: 100%;
          padding: 1rem 0;
        }

        @media (max-width: $tiny) {
          padding: .75rem 0;
        }
      }

      .restaurant-pic {
        height: 20rem;
        @extend %bg-cover;
      }

      .restaurant-texte {
        flex-grow: 1;
        padding: 3rem 2rem 4rem;
        background-color: $beige-transp;

        @media (max-width: $medium) {
          padding: 1.5rem 1rem 2rem;
        }

        h2 {
          text-align: center;
          font: 500 2.4rem $font-family-base;
          color: $marron-moyen;

          @media (max-width: $medium) {
            font-size: 2rem;
          }
        }

        p:last-of-type {
          margin: auto auto 0;
        }

        a {
          display: block;
          width: 15.5rem;
          margin: 1.5rem auto 0;
          padding: 1rem 1.5rem;
          text-align: center;
          text-transform: uppercase;
          text-decoration: none;
          outline: none;
          cursor: pointer;
          color: $white;
          background-color: $vert;

          @media (max-width: $large) {
            font-size: 1.5rem;
          }

          @media (max-width: $medium) {
            margin-top: 1rem;
          }

          &:hover {
            animation: {
              name: glow-button;
              duration: 1s;
              iteration-count: infinite;
              direction: alternate;
            }
          }
        }
      }
    }
