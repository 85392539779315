  // Style hébergement

  //   Style vignette activités été et hiver

    .activites-wrapper {
      margin: -1rem;

      @media (max-width: $tiny) {
        margin: -.75rem 0;
      }
    }

    .activite-wrapper {
      position: relative;
      width: calc(25% - 2rem);
      margin: 1rem;

      @media (max-width: $large) {
        width: calc(33.33% - 2rem);
      }

      @media (max-width: $medium) {
        width: calc(50% - 2rem);
      }

      @media (max-width: $tiny) {
        width: 100%;
        margin: .75rem 0;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background-color: transparent;
        transition: background-color .3s ease-in-out;
        z-index: 1;
      }

      &:hover:before {
        background-color: rgba($anthracite, .5);
      }

      a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }

      h2 {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        padding: 1rem;
        font: 400 2rem $font-family-base;
        text-transform: none;
        text-align: center;
        transform: translateY(-50%);
        color: $white;
        z-index: 1;

        span {
          padding: 0 .3rem;
          background-color: $marron-grizzly;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    // Style page produit activité été et hiver

    .activite {
      .iframe-wrapper {
        margin-top: 3rem;
      }

      .basic-btn-wrapper {
        margin-top: 3rem;
      }

      .basic-btn:hover {
        animation-duration: 1s;
      }
    }
