  // Style actualité

    // Style vignette actualité

      .news-single {
        position: relative;

        &:hover {
          .news-single-pic {
            transform: scale(1.05);
          }

          .basic-btn {
            animation-duration: 1s;
          }
        }
      }

      .news-single-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .news-single-pic-wrapper {
        height: 30rem;
        overflow: hidden;

        @media (max-width: $large) {
          height: 20rem;
        }

        @media (max-width: $tiny) {
          height: 15rem;
        }
      }

      .news-single-pic {
        width: 100%;
        height: 100%;
        @extend %bg-cover;
        transition: transform .5s ease-in-out;
      }

      .news-single-content {
        flex-grow: 1;
        padding: 3rem 1rem 4rem;
        background-color: $beige-transp;

        @media (max-width: $medium) {
          padding: 1.5rem 1rem 2rem;
        }

        .news-single-date, .news-single-duree {
          margin: 0 0 1rem;
        }

        .news-single-date {
          font-size: 1.3rem;

          @media (max-width: $medium) {
            margin: 0;
          }
        }

        .news-single-duree {
          font-weight: 500;
          font-size: 1.8rem;
          color: $vert-clair;

          i {
            margin-right: .3rem;
          }
        }

        .txt-wrapper {
          margin-top: 2rem;
        }

        .basic-btn-wrapper {
          margin-top: auto;
          padding-top: 3rem;

          @media (max-width: $medium) {
            padding-top: 1.5rem;
          }
        }
      }

    // Spécifique page Actualités

      .actualites {
        margin: -1rem;

        @media (max-width: $medium) {
          margin: -1rem 0;
        }

        @media (max-width: $tiny) {
          margin: -.75rem 0;
        }

        .news-single {
          width: 50%;
          padding: 1rem;

          @media (max-width: $medium) {
            width: 100%;
            padding: 1rem 0;
          }

          @media (max-width: $tiny) {
            padding: .75rem 0;
          }
        }
      }

    // Spécifique page Actualité

      .actualite {
        .news-duree {
          margin-bottom: 3rem;
          text-align: center;
          font-weight: 500;
          font-size: 2rem;
          color: $vert-clair;

          @media (max-width: $medium) {
            font-size: 1.7rem;
          }

          i {
            margin-right: .3rem;
          }
        }

        img {
          width: 100%;
          height: auto;
          margin: 2rem 0;
        }

        .iframe-wrapper {
          margin: 3rem 0;
        }

        .basic-btn-wrapper .basic-btn:hover {
          animation-duration: 1s;
        }
      }

    // Style pagination

      .pagination {
        padding: 0 0 5rem;
        text-align: center;
        font-weight: 700;
        font-size: 1.8rem;

        span, a {
          display: inline-block;
          width: 4.2rem;
          height: 4.2rem;
        }

        .current {
          padding: 1rem;
          color: $white;
          background-color: $vert-clair;
        }

        a {
          padding: 1rem;
          text-decoration: none;
          background-color: rgba($beige-grizzly, .7);
          transition: all .3s ease-in-out;

          &:hover {
            color: $white;
            background-color: $vert;
          }
        }
      }

