
	// Pied de page

	footer {
    position: relative;
    width: 100%;
    font-size: 1.3rem;
    color: $beige-clair;

    p {
      margin: 0;
    }

		.footer__top {
      align-items: center;
      padding: 2rem;
      background-color: $vert;
      box-shadow: .5rem .5rem 2rem 0 rgba($marron-grizzly, .5);

      @media (min-width: $x-small + 1) {
        flex-wrap: nowrap;
      }

      @media (max-width: $tiny) {
        padding: 1.5rem;
      }

      h3 {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 400;
        color: $white;
      }

      .logo-ferme-grizzly {
        position: absolute;
        width: 20rem;
        height: 14.3rem;
        top: -4rem;
        left: 2rem;
        z-index: 3;

        @media (max-width: $tiny) {
          top: -2.2rem;
          left: 50%;
          width: 15rem;
          height: 10.8rem;
          transform: translateX(-50%);
        }
      }

      .footer__description {
        align-self: flex-end;
        padding-top: 10rem;

        @media (max-width: $x-small) {
          width: 100%;
          margin-bottom: 2rem;
        }

        @media (max-width: $tiny) {
          padding-top: 9rem;
        }

        .title {
          margin-bottom: 1rem;
          font-size: 1.6rem;
          text-transform: uppercase;
        }

        p {
          max-width: 36rem;
        }
      }

      .footer__coordonnees {
        min-width: 30rem;
        padding: 0 7rem;

        @media (max-width: $large) {
          min-width: 24rem;
          padding: 0 4rem;
        }

        @media (max-width: $x-small) {
          min-width: 19rem;
          padding: 0 3rem 0 0;
        }

        @media (max-width: $tiny) {
          width: 100%;
          min-width: unset;
          margin-bottom: 2rem;
          padding: 0;
        }

        .phone-link {
          display: block;
          padding: 1.5rem 0;
          text-decoration: none;
          color: $white;

          span {
            transition: background-color .3s ease-in-out;
          }

          &:hover span {
            background-color: $vert-clair;
          }
        }

        .contact-btn {
          display: inline-block;
          padding: 1rem 1.5rem;
          text-transform: uppercase;
          text-decoration: none;
          outline: none;
          font-size: 1.5rem;
          cursor: pointer;
          color: $white;
          background-color: $vert-clair;
          transition: all .3s ease-in-out;

          &:hover {
            color: $marron-grizzly;
            background-color: $beige-clair;
          }
        }
      }

      .footer__reseaux {
        min-width: 14.5rem;
        margin-bottom: -1rem;

        @media (max-width: $tiny) {
          width: 100%;
          min-width: unset;
        }

        a {
          display: inline-block;
          margin: 0 1rem 1.3rem 0;
          padding: 1rem;
          width: 5rem;
          height: 5rem;
          font-size: 2.5rem;
          text-decoration: none;
          text-align: center;
          color: rgba($beige-clair, .8);
          background-color: rgba($vert-clair, .2);
          transition: background-color .3s ease-in-out;

          &:before {
            vertical-align: middle;
          }

          &:hover {
            background-color: $vert-clair;
          }
        }

        .reseaux-wrapper {
          width: 12rem;
        }
      }
    }

    .footer__bottom {
      padding: 1rem 2rem;
      text-align: center;
      font-size: 1.2rem;
      background-color: $vert-fonce;

      a {
        color: $white;
      }
    }
	}
