
	// Fonts (Polices de caractère)

    // Slick

      @font-face {
        font-family: 'slick';
          src: url('../fonts/slick/slick.woff2') format('woff2'),
             url('../fonts/slick/slick.woff') format('woff'),
               url('../fonts/slick/slick.ttf') format('truetype'),
           url('../fonts/slick/slick.svg#slick') format('svg');
      }

    // Font-awesome

      @font-face {
        font-family: 'font-awesome';
          src: url('../fonts/fontawesome/fa-brands-400.woff2') format('woff2'),
             url('../fonts/fontawesome/fa-brands-400.woff') format('woff'),
               url('../fonts/fontawesome/fa-brands-400.ttf') format('truetype'),
           url('../fonts/fontawesome/fa-brands-400.svg#FABrands') format('svg');
          font-weight: 400;
          font-style: normal;
      }

      @font-face {
        font-family: 'font-awesome';
          src: url('../fonts/fontawesome/fa-solid-900.woff2') format('woff2'),
             url('../fonts/fontawesome/fa-solid-900.woff') format('woff'),
               url('../fonts/fontawesome/fa-solid-900.ttf') format('truetype'),
           url('../fonts/fontawesome/fa-solid-900.svg#FASolid') format('svg');
          font-weight: 900;
          font-style: normal;
      }

		// Chivo

      @font-face {
          font-family: 'Chivo';
          src: url('../fonts/chivo/Chivo-Light.woff2') format('woff2'),
               url('../fonts/chivo/Chivo-Light.woff') format('woff'),
               url('../fonts/chivo/Chivo-Light.ttf') format('truetype'),
           url('../fonts/chivo/Chivo-Light.svg#ChivoLight') format('svg');
          font-weight: 300;
          font-style: normal;
      }

      @font-face {
          font-family: 'Chivo';
          src: url('../fonts/chivo/Chivo-Regular.woff2') format('woff2'),
               url('../fonts/chivo/Chivo-Regular.woff') format('woff'),
               url('../fonts/chivo/Chivo-Regular.ttf') format('truetype'),
           url('../fonts/chivo/Chivo-Regular.svg#ChivoRegular') format('svg');
          font-weight: 400;
          font-style: normal;
      }

      @font-face {
          font-family: 'Chivo';
          src: url('../fonts/chivo/Chivo-Bold.woff2') format('woff2'),
               url('../fonts/chivo/Chivo-Bold.woff') format('woff'),
               url('../fonts/chivo/Chivo-Bold.ttf') format('truetype'),
           url('../fonts/chivo/Chivo-Bold.svg#ChivoBold') format('svg');
          font-weight: 500;
          font-style: normal;
      }

      @font-face {
          font-family: 'Chivo';
          src: url('../fonts/chivo/Chivo-Black.woff2') format('woff2'),
               url('../fonts/chivo/Chivo-Black.woff') format('woff'),
               url('../fonts/chivo/Chivo-Black.ttf') format('truetype'),
           url('../fonts/chivo/Chivo-Black.svg#ChivoBlack') format('svg');
          font-weight: 700;
          font-style: normal;
      }

      @font-face {
          font-family: 'Chivo';
          src: url('../fonts/chivo/Chivo-LightItalic.woff2') format('woff2'),
               url('../fonts/chivo/Chivo-LightItalic.woff') format('woff'),
               url('../fonts/chivo/Chivo-LightItalic.ttf') format('truetype'),
           url('../fonts/chivo/Chivo-LightItalic.svg#ChivoLightItalic') format('svg');
          font-weight: 300;
          font-style: italic;
      }
