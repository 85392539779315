
	// Navigation

		header {
      position: fixed;
      width: 100%;
      z-index: 4;

			&.is-mobile-nav-open {
				.links-wrapper {
					pointer-events: auto;
					opacity: 1;
					transition: opacity .2s ease-out;
				}
			}

      .top-nav {
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        font-size: 1.3rem;
        color: $white;
        background-color: $vert;

        @media (max-width: $medium) {
          display: none;
        }

        p, a {
          margin: 0;
          padding: 1rem;
        }

        p {
          display: inline-block;
        }

        a {
          text-decoration: none;
          color: $white;
        }

        div:first-of-type {
          i {
            margin-right: .3rem;
          }

          a {
            span {
              transition: background-color .3s ease-in-out;
            }

            &:hover span {
              background-color: $vert-clair;
            }
          }
        }
      }

      .bottom-nav {
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        background-color: $beige-clair;
        box-shadow: .2rem .2rem .7rem 0 rgba($marron-grizzly, .2);
      }

      .header__logo {
        height: 6rem;
        padding: 1rem;

        &:hover {
          .fill-beige {
            fill: $marron-grizzly;
          }

          .fill-marron {
            fill: $beige-grizzly;
          }
        }
      }

      .grizzly {
        width: 7.5rem;
        height: 4rem;
      }

      .links-wrapper {
        position: relative;
        margin: 0;

        @media (max-width: $large) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 6rem;
          left: 0;
          right: 0;
          bottom: 0;
          height: calc(100vh - 6rem);
          pointer-events: none;
          opacity: 0;
          background-color: rgba($vert, .9);
          transition: opacity .3s ease-in-out;
        }

        .ornament {
          position: absolute;
          top: 50%;
          left: 1rem;
          width: 0;
          height: 2.4rem;
          content: '';
          background-color: $vert-clair;
          transition: all .3s $light-bounce;
          transform: translateY(-50%);
          z-index: 0;
        }

        a {
          display: inline-block;
          padding: 2rem 1rem;
          font-weight: 500;
          font-size: 1.8rem;
          text-decoration: none;
          color: $marron-moyen;
          text-transform: uppercase;
          letter-spacing: -.05rem;
          transition: color .3s ease-in-out;

          @media (max-width: $large) {
            padding: 1rem;
            font-weight: 400;
            text-align: center;
            color: $beige-clair;

            &:hover {
              color: $white;
            }
          }

          &.is-hovered, &.is-visible {
            color: $white;
          }

          span {
            position: relative;
            padding: .1rem .3rem;
            z-index: 1;
            transition: background-color .2 ease-in-out;
          }
        }
      }

			.hamburger {
				justify-content: center;
				align-items: center;
        height: 6rem;
				margin-left: auto;
        outline: none;

				@media (min-width: $large + 1) {
					display: none;
				}

				.hamburger-label {
					margin-right: 1rem;
			    text-transform: uppercase;
			    font-weight: 400;
          color: $vert;
				}
			}
		}
