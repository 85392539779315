  // Style hébergement

    // Style vignette hébergement

      .hebergement-item {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 50%;
        padding: 0 1rem;

        @media (max-width: $medium) {
          width: 100%;
          padding: .75rem 0;
        }

        &:hover {
          .hebergement-pic {
            transform: scale(1.05) rotate(0.001deg);
          }

          .basic-btn {
            animation: {
              name: glow-button;
              duration: 1s;
              iteration-count: infinite;
              direction: alternate;
            }
          }
        }

        .hebergement-link {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }

        .hebergement-pic-wrapper {
          height: 30rem;
          overflow: hidden;

          @media (max-width: $large) {
            height: 20rem;
          }

          @media (max-width: $tiny) {
            height: 15rem;
          }
        }

        .hebergement-pic {
          width: 100%;
          height: 100%;
          @extend %bg-cover;
          transition: transform .5s ease-in-out;
        }

        .hebergement-txt {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          padding: 3rem 1rem 4rem;
          background-color: $white;

          @media (max-width: $medium) {
            padding: 1.5rem 1rem 2rem;
          }
        }

        h3 {
          text-align: center;
        }

        i {
          margin-right: .3rem;
        }

        .capacite-accueil {
          margin: 0;
          font-style: italic;
          color: $vert-fonce;
        }

        .basic-btn-wrapper {
          margin-top: auto;
          padding-top: 2rem;

          @media (max-width: $medium) {
            padding-top: 1.5rem;
          }
        }
      }

    // Style pages uniques hébergement

    .hebergement-infos {
      overflow: hidden;

      h2 {
        color: $vert-clair;
      }

      .infos-wrapper {
        width: 100%;

        @media (min-width: $small + 1) {
          padding: 0 2rem;
        }
      }

      .dedans-wrapper {
        max-width: 33rem;

        @media (max-width: $x-large) {
          max-width: 50%;
        }

        @media (max-width: $small) {
          max-width: unset;
        }

        .option-dortoir {
          margin-top: 2rem;
          font-size: 1.8rem;
          color: $vert;
        }
      }

      .dehors-wrapper {
        max-width: 27rem;

        @media (max-width: $x-large) {
          max-width: 50%;
        }

        @media (max-width: $small) {
          max-width: unset;
          margin-top: 2rem;
        }
      }

      .equipements-wrapper {
        @media (min-width: $x-large + 1) {
          max-width: calc(100% - 60rem);
        }

        @media (max-width: $x-large) {
          margin-top: 3rem;
        }

        @media (max-width: $small) {
          margin-top: 2rem;
        }

        .icons-wrapper {
          align-items: flex-start;
        }

        .icon {
          position: relative;
          width: 8.2rem;
          height: 8.2rem;
          padding: 2.5rem;
          background: {
            repeat: no-repeat;
            position: center center;
          }

          @media (max-width: $tiny) {
            width: 6.7rem;
            height: 6.7rem;
            padding: 1rem;
          }

          &:hover p {
            opacity: 1;
          }

          p {
            position: absolute;
            top: 6.7rem;
            left: 50%;
            width: 26rem;
            margin: 0;
            pointer-events: none;
            font-size: 1.4rem;
            font-weight: 400;
            text-transform: uppercase;
            text-align: center;
            color: $marron-grizzly;
            opacity: 0;
            transform: translateX(-50%) translateZ(0);
            transition: opacity .3s $light-bounce;
            z-index: 1;

            @media (max-width: $x-large) {
              width: 17rem;
            }

            @media (max-width: $small) {
              width: 12rem;
            }

            @media (max-width: $tiny) {
              width: 9rem;
            }

            span {
              padding: .2rem .4rem;
              background-color: $white;
              box-shadow: .2rem .2rem 1rem 0 rgba($marron-grizzly, .15);
            }
          }

          &.four {
            background-image: url('/dist/img/icones-gites/four.png');
          }

          &.micro-onde {
            background-image: url('/dist/img/icones-gites/micro-onde.png');
          }

          &.lave-vaisselle {
            background-image: url('/dist/img/icones-gites/lave-vaisselle.png');
          }

          &.lave-linge {
            background-image: url('/dist/img/icones-gites/lave-linge.png');
          }

          &.frigidaire {
            background-image: url('/dist/img/icones-gites/frigidaire.png');
          }

          &.cafetiere {
            background-image: url('/dist/img/icones-gites/cafetiere.png');
          }

          &.bouilloire {
            background-image: url('/dist/img/icones-gites/bouilloire.png');
          }

          &.mixer {
            background-image: url('/dist/img/icones-gites/mixer.png');
          }

          &.poele {
            background-image: url('/dist/img/icones-gites/poele.png');
          }

          &.television {
            background-image: url('/dist/img/icones-gites/television.png');
          }

          &.wifi {
            background-image: url('/dist/img/icones-gites/wifi.png');
          }

          &.douche {
            background-image: url('/dist/img/icones-gites/douche.png');
          }

          &.toilettes {
            background-image: url('/dist/img/icones-gites/toilettes.png');
          }
        }
      }

      .hebergement-images-wrapper {
        width: 100%;
        margin: 2rem 0 0;

        .produit__image-small {
          height: 55rem;
        }
      }
    }

    .hebergement-content {
      h2 {
        color: $vert-clair;
      }

      // Tarifs
      .tarifs-wrapper {
        width: 100%;
        margin-bottom: 5rem;

        .table-wrapper {
          overflow-x: auto;

          @media (max-width: $x-small) {
            white-space: nowrap;
          }
        }

        table {
          min-width: 67.4rem;
          margin: -.2rem 0 0;
          table-layout: auto;
          border-collapse: separate;
          border-spacing: .2rem;

          @media (max-width: $large) {
            font-size: 1.5rem;
          }

          @media (max-width: $medium) {
            font-size: 1.3rem;
          }
        }

        th, td {
          padding: 1rem;
        }

        thead {
          color: $white;
          background-color: $beige-grizzly;

          th {
            font-weight: 400;
            text-transform: uppercase;
          }
        }

        tbody {
          tr {
            td {
              font-weight: 300;

              &:first-of-type {
                font-weight: 400;
                background-color: rgba($beige-grizzly, .3);
              }
            }

            &:nth-of-type(even) {
              background-color: $beige-transp;
            }
          }
        }

        .dispo {
          background-color: rgba($anthracite, .1);
        }
      }
      // Calendrier
      .calendrier-wrapper {
        width: calc(100% - 35rem);
        padding-right: 2rem;

        @media (max-width: $large) {
          width: 100%;
          margin-bottom: 3rem;
          padding-right: 0;
        }

        .calendrier {
          h2 {
            margin: 0;
            font: 500 2.4rem $font-family-base;
            color: $marron-moyen;

            @media (max-width: $medium) {
              margin-bottom: 1rem;
              font-size: 1.9rem;
            }
          }

          button {
            outline: none;
            border-radius: 0;
          }

          table {
            margin-bottom: 0;
          }
        }
      }

      .fc-unthemed td.fc-today {
        background: rgba($vert-clair, .15);
      }

      .fc-day-grid-event.fc-h-event.fc-event {
        margin: 0;

        .fc-title {
          padding: .2rem .4rem;
          text-transform: uppercase;
          text-align: center;
          font-size: 1.2rem;
          color: $white;
        }
      }

      // Autres
      .autres-wrapper {
        width: 35rem;
        padding-right: 2rem;

        @media (max-width: $large) {
          width: 100%;
          padding-right: 0;
        }
      }
    }

    // Plan

    .plan {
      margin-top: 5rem;

      @media (max-width: $large) {
        margin-top: 3rem;
      }
    }
