// Style page d'accueil

  .accueil-slider {
    .accueil-slider-wrapper {
      position: relative;
      margin-bottom: 10.2rem;

      @media (max-width: $large) {
        margin-bottom: 7.4rem;
      }

      @media (max-width: $medium) {
        margin-bottom: 6.3rem;
      }

      @media (max-width: $tiny) {
        margin-bottom: 0;
      }

      .logo-grizzly-big {
        height: auto;

        @media (min-width: $tiny + 1) {
          position: absolute;
          bottom: 0;
          left: calc(50% + .3rem);
          width: 27.6rem;
          transform: translateX(-50%) translateY(10.2rem);
          z-index: 1;
        }

        @media (max-width: $large) {
          width: 20rem;
          transform: translateX(-50%) translateY(7.4rem);
        }

        @media (max-width: $medium) {
          width: 17rem;
          transform: translateX(-50%) translateY(6.3rem);
        }

        @media (max-width: $tiny) {
          display: block;
          margin: 1rem auto 0;
          transform: translateX(0) translateY(0);
        }
      }
    }

    .slides-wrapper {
      height: 55rem;

      @media (max-width: $large) {
        height: 45rem;
      }

       @media (max-width: $medium) {
        height: 30rem;
       }

       @media (max-width: $tiny) {
        height: 22rem;
       }
    }

    .slide {
      height: 100%;
      @extend %bg-cover;
    }
  }

  .accueil-introduction {
    padding: 3rem 0 5rem;

    @media (max-width: $large) {
      padding: 2rem 0 1rem;
    }

    h1 span {
      font-size: 2.5rem;
      font-weight: 400;
      text-transform: uppercase;
      color: $vert-clair;

      @media (max-width: $medium) {
        font-size: 2rem;
      }
    }
  }

  .accueil-gites {
    padding: 4rem 2rem 2rem;

    @media (max-width: $large) {
      padding: 3rem 2rem 2rem;
    }

    @media (max-width: $small) {
      padding: 2rem 1.5rem 1.5rem;
    }

    .flex-container {
      margin: 0 -1rem;

      @media (max-width: $medium) {
        margin: -.75rem 0;
      }
    }
  }

  .accueil-actualites {
    padding-bottom: 0 !important;

    .actu-activites-wrapper {
      margin: 0 -1rem;
    }

    .last-actu-wrapper, .activites-random-wrapper {
      padding: 0 1rem;
    }

    .last-actu-wrapper {
      width: 60%;

      @media (max-width: $medium) {
        width: 100%;
      }

      .news-single-content {
        padding-bottom: 6rem;
      }

      .all-actus {
        position: absolute;
        right: 1rem;
        bottom: 1.5rem;
        padding: 1rem;
        text-decoration: none;
        color: $marron-grizzly;
        transition: color .3s ease-in-out;
        z-index: 2;

        span {
          text-decoration: underline;
        }

        i {
          margin-right: .3rem;
        }

        &:hover {
          color: $vert-clair;
        }
      }
    }

    .activites-random-wrapper {
      width: 40%;

      @media (max-width: $medium) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 2rem 0 0;
      }

      @media (max-width: $tiny) {
        margin-top: 1.5rem;
      }

      h3 {
        margin-bottom: 2rem;
        padding: .3rem .5rem;
        text-align: right;
        font-weight: 400;
        color: $white;
        background-color: $beige-grizzly;

        @media (max-width: $medium) {
          width: 100%;
        }

        @media (max-width: $tiny) {
          margin-bottom: 1.5rem;
        }
      }
    }

    .activite-random-wrapper {
      position: relative;
      @extend %bg-cover;

      @media (min-width: $medium + 1) {
        flex: 1 1 auto;
      }

      @media (max-width: $medium) {
        width: calc(50% - 1rem);
        height: 28rem;
      }

      @media (max-width: $tiny) {
        width: 100%;
        height: 18rem;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background-color: rgba($anthracite, .4);
        transition: background-color .3s ease-in-out;
        z-index: 1;
      }

      &:hover:before {
        background-color: transparent;
      }

      &.activite-random-ete {
        margin-bottom: 2rem;

        @media (max-width: $medium) {
          margin-bottom: 0;
        }

        @media (max-width: $tiny) {
          margin-bottom: 1.5rem;
        }
      }

      .activite-random-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }

      h4 {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0;
        padding: 2rem;
        text-align: center;
        font: 400 1.8rem $font-family-base;
        text-transform: uppercase;
        color: $white;
        transform: translateY(-50%);
        z-index: 1;

        @media (max-width: $small) {
          padding: 1rem;
          font-size: 1.5rem;
        }

        i {
          margin-bottom: 2rem;
          font-size: 5rem;

          @media (max-width: $small) {
            margin-bottom: 1rem;
            font-size: 3.5rem;
          }
        }
      }
    }
  }

  .accueil-reseaux {
    padding-bottom: 3rem;

    @media (max-width: $tiny) {
      padding-bottom: 2rem;
    }

    > .flex-container {
      @media (max-width: $medium) {
        flex-direction: column;
        align-items: center;
      }
    }

    .instagram-wrapper {
      width: calc(100% - 50rem);

      @media (max-width: $medium) {
        order: 1;
        max-width: 50rem;
        width: 100%;
        margin-top: 2rem;
      }

      @media (max-width: $tiny) {
        margin-top: 1.5rem;
      }
    }

    .instagram-content {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 100%;

      @media (max-width: $large) and (min-width: $medium + 1) {
        padding-top: 200%;
      }
    }

    .instagram-block {
      display: block;
      position: absolute;
      top: 0;
      width: 50%;
      padding-top: 50%;
      overflow: hidden;
      @extend %bg-cover;

      @media (max-width: $large) and (min-width: $medium + 1) {
        width: 100%;
        padding-top: 100%;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content:'';
        background-color: transparent;
        transition: background-color .3s ease-in-out;
      }

      &:hover:before {
        background-color: rgba($vert-clair, .3);
      }

      & > * {
        z-index: 2;
      }

      & > a {
        z-index: 3;
      }

      &:nth-of-type(1) {
        left: 0;
        top: 0;
      }

      &:nth-of-type(2) {
        left: 50%;
        top: 0;

        @media (max-width: $large) and (min-width: $medium + 1) {
          left: 0;
          top: 50%;
        }
      }

      &:nth-of-type(3) {
        left: 0;
        top: 50%;

        @media (max-width: $large) and (min-width: $medium + 1) {
          display: none;
        }
      }

      &:nth-of-type(4) {
        left: 50%;
        top: 50%;

        @media (max-width: $large) and (min-width: $medium + 1) {
          display: none;
        }
      }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 4rem;
        color: $white;
        transform: translateX(-50%) translateY(-50%);
        z-index: 2;
      }
    }

    .facebook-wrapper {
      width: 50rem;
      padding-left: 2rem;

      @media (max-width: $medium) {
        max-width: 50rem;
        width: 100%;
        padding-left: 0;
      }
    }
  }

  .accueil-avis {
    padding-bottom: 8rem;

    @media (max-width: $large) {
      padding-bottom: 5rem;
    }

    @media (max-width: $tiny) {
      padding-bottom: 3rem;
    }

    .flex-container {
      align-items: center;
      flex-wrap: nowrap;

      @media (max-width: $small) {
        flex-direction: column;
      }
    }

    .avis-image {
      min-width: 16rem;
      width: 16rem;
      height: 16rem;
      margin-right: 3rem;
      border-radius: 50%;
      @extend %bg-cover;

      @media (max-width: $small) {
        min-width: 12rem;
        width: 12rem;
        height: 12rem;
        margin: 0 0 2rem;
      }
    }

    .avis-titre {
      font-size: 1.8rem;

      @media (max-width: $small) {
        text-align: center;
      }
    }
  }

