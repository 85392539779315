
	// Styles Communs

		html {
			display: flex;
			flex-direction: column;
		}

		body {
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			font: 300 1.6rem $font-family-base;
      color: $anthracite;

      @media (min-width: $bg-large + 1) {
        background: {
          image: url('/dist/img/ferme-grizzly-background-planches.jpg');
          repeat: no-repeat;
          position: center center;
          size: cover;
          attachment: fixed;
        }
      }

      &.is-fixed {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
		}

		main {
			flex: 1 1 auto;
      width: 100%;
      background:{
        image: url('/dist/img/sapins-ferme-grizzly.png');
        repeat: no-repeat;
        position: left bottom;
      }

      @media (min-width: $bg-large + 1) {
        background-color: $white;
        box-shadow: .5rem .5rem 2rem 0 rgba($marron-grizzly, .5);
      }

      &.container {
        margin-top: 9.5rem;
        padding: 2rem;

        @media (max-width: $medium) {
          margin-top: 6.5rem;
        }

        @media (max-width: $tiny) {
          padding: 1.5rem;
        }
      }
		}

    ::selection {
      color: $white;
      background: $vert-clair;
    }

    h1 {
      margin: 0 0 3rem;
      font: 700 4rem $font-family-base;
      text-align: center;
      color: $vert-fonce;

      @media (max-width: $medium) {
        margin-bottom: 2rem;
        font-size: 3rem;
      }
    }

    h2 {
      margin: 0 0 2rem;
      font: 400 2.8rem $font-family-base;
      text-transform: uppercase;
      color: $vert;

      @media (max-width: $medium) {
        font-size: 2.3rem;
      }
    }

    h3 {
      margin: 0 0 2rem;
      font: 500 2.4rem $font-family-base;
      color: $marron-moyen;

      @media (max-width: $medium) {
        margin-bottom: 1rem;
        font-size: 2rem;
      }
    }

    ul {
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 1rem;

        &:before {
          position: absolute;
          left: -1.6rem;
          top: .2rem;
          content: '\f1bb';
          font: 900 1.4rem 'font-awesome';
          color: $vert;
        }
      }
    }

    path {
      transition: fill .3s ease-in-out;
    }

    ///// Corrections Slick slider

    .slick-list, .slick-track, .slick-slide, .slick-slide div {
      height: 100%;
    }

    ///// Style SVG

    .fill-marron {
      fill: $marron-grizzly;
    }

    .fill-marron-moyen {
      fill: $marron-moyen;
    }

    .fill-beige {
      fill: $beige-grizzly;
    }

    .fill-beige-clair {
      fill: $beige-clair;
    }

    ///// Iframe responsive fullscreen

    .iframe-wrapper {
      position: relative;
    }

    .iframe-sub-wrapper {
      position: relative;
      height: 0;
      padding: 0 0 56.25%;

        iframe, embed, object {
        position: absolute;
          top: 0;
          left: 0;
          width: 100% !important;
          height: 100% !important;
      }
    }

    ////// Dimensions custo container

    %container {
      position: relative;
      margin: 0 auto;
      padding: 0 2rem;

      @media (max-width: $small) {
        padding: 0 1.5rem;
      }
    }

    .container {
      max-width: 124rem;
      @extend %container;
    }

    .container-small {
      max-width: 94rem;
      @extend %container;

      @media (max-width: $medium) {
        padding: 0;
      }
    }

    .container-xsmall {
      max-width: 84rem;
      @extend %container;

      @media (max-width: $medium) {
        padding: 0;
      }
    }

    ///// Styles custo containers

    .p-section {
      padding: 5rem 0;

      @media (max-width: $large) {
        padding: 3rem 0;
      }
    }

    .p-beige {
      margin-top: 5rem;
      padding: 4rem 2rem;
      background-color: $beige-transp;

      @media (max-width: $large) {
        margin-top: 3rem;
        padding: 3rem 2rem;
      }

      @media (max-width: $small) {
        padding: 2rem 1.5rem;
      }

      @media (max-width: $tiny) {
        padding: 2rem 1rem;
      }
    }

    ///// Style boutons

    @keyframes glow-button {
      from {
        background-color: $vert;
      }
      to {
        background-color: $vert-clair;
      }
    }

    .basic-btn-wrapper {
      display: block;
      margin-top: 2rem;
      text-align: center;

      @media (max-width: $medium) {
        margin-top: 1.5rem;
      }

      .basic-btn {
        display: inline-block;
        padding: 1rem 1.5rem;
        text-transform: uppercase;
        text-decoration: none;
        outline: none;
        cursor: pointer;
        color: $white;
        background-color: $vert;

        @media (max-width: $large) {
          font-size: 1.5rem;
        }

        &:hover {
          animation: {
            name: glow-button;
            duration: .1s;
            iteration-count: infinite;
            direction: alternate;
          }
        }
      }
    }

  ///// Style entête

    .page-entete {
      position: relative;

      .page-entete__image {
        height: 40rem;
        @extend %bg-cover;

        @media (max-width: $medium) {
          height: 30rem;
        }

        @media (max-width: $tiny) {
          height: 22rem;
        }
      }

      h1 {
        position: absolute;
        top: 40rem;
        left: 0;
        right: 0;
        margin: 0;
        padding: 2rem;
        font: 500 3.5rem $font-family-base;
        text-align: center;
        color: $marron-moyen;
        transform: translateY(-50%);
        z-index: 1;

        @media (max-width: $medium) {
          top: 30rem;
          font-size: 2.8rem;
        }

        @media (max-width: $tiny) {
          top: 22rem;
          font-size: 2.2rem;
        }

        span {
          padding: 0 .5rem;
          background-color: $beige-clair;
          box-shadow: .2rem .2rem .7rem 0 rgba($marron-grizzly, .2);
        }
      }

      .container-small {
        padding: 8rem 2rem 0;

        @media (max-width: $large) {
          padding-top: 6.5rem;
        }

        @media (max-width: $tiny) {
          padding: 5rem 1.5rem 0;
        }
      }

      .news-date {
        margin: 0;
        text-align: center;
        text-transform: uppercase;
      }

      .capacite-accueil {
        margin: 0;
        text-align: center;
        font-size: 2rem;
        color: $vert;
      }
    }

    ///// Style slider produits

    .produit-images-wrapper {
      margin-bottom: 3rem;

      .produit-slider {
        height: 45rem;

        @media (max-width: $medium) {
          height: 32rem;
        }

        @media (max-width: $tiny) {
          height: 20rem;
        }
      }

      .produit__image-big {
        outline: none;
      }

      .produit__image-small {
        width: 100%;
        height: 45rem;
        @extend %bg-cover;
      }

      .bigger {
        margin-top: 1rem;
        text-align: right;
        font-size: 1.3rem;
        font-style: italic;
      }
    }

    ///// Plan du site
    .plan-du-site {
      a {
        cursor: pointer;
        transition: color .3s ease-in-out;

        &:hover {
          color: $vert;
        }
      }

      .plan-item {
        margin-bottom: 1.5rem;
      }

      .item-sub-wrapper, .item-last-wrapper a:first-of-type {
        position: relative;

        &:before {
          position: absolute;
          top: 0;
          width: 1.7rem;
            height: 1.7rem;
          content: '';
          border-left: .1rem solid rgba($anthracite, .2);
          border-bottom: .1rem solid rgba($anthracite, .2);
        }
      }

      .item-sub-wrapper:before {
          left: 2.5rem;
      }

      .item-last-wrapper {
        display: flex;
        flex-direction: column;

        a:first-of-type:before {
          left: -1.5rem;
        }
      }

      .plan-item-titre, .item-sub, .item-last {
        position: relative;
        display: block;
        padding: .5rem 1rem;
      }

      .plan-item-titre {
        font: 500 2.4rem $font-family-base;
        color: $marron-moyen;

        @media (max-width: $medium) {
          font-size: 2rem;
        }
      }

      .item-sub {
        margin-left: 4rem;
      }

      .item-last {
        display: inline-block;
        margin-left: 9rem;
      }
    }

    ///// Mentions légales
    .mentions-legales h2:not(:first-of-type) {
      margin-top: 3rem;
    }
