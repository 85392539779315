
  // Style page contact

    .contact {
      .form-wrapper {
        width: calc(60% - 2rem);
        margin: 0 2rem 0 0;

        @media (max-width: $large) {
          width: 100%;
          margin: 0 0 2rem;
        }

        label.error {
          margin: .5rem 0;
          font-weight: 500;
          color: $error;
        }
      }

      .form-group-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1rem;

        .form-group {
          width: 50%;
          padding: 0 1rem;

          @media (max-width: $small) {
            width: 100%;
          }
        }
      }

      .form-group {
        margin-bottom: 1.5rem;

        @media (min-width: $small + 1) {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .form-message {
        padding: 1rem;
        margin-bottom: 2rem;
        font-weight: 400;
        color: $white;

        &.form-notice {
          background-color: $vert-clair;
        }

        &.form-erreur {
          background-color: $error;
        }
      }

      .basic-btn-wrapper {
        padding: 0;
        outline: none;
        box-shadow: none;
        background-color: transparent;

        .basic-btn {
          animation-duration: 1s;
        }
      }

      #rc-imageselect, .g-recaptcha {
        @media (max-width: $tiny) {
          transform: scale(0.8) !important;
          transform-origin: 0 50%;
        }
      }

      .map {
        width: 40%;

        @media (max-width: $large) {
          width: 100%;
          height: 30rem;
        }

        @media (max-width: $small) {
          height: 22rem;
        }
      }
    }
